/** @jsx jsx */
import { jsx, Box, Heading, Text } from "theme-ui"
import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import Slicezone from "../components/Slicezone"

export default function Post({ data }) {
  const { first_publication_date, tags } = data.prismicPosts
  const post = data.prismicPosts.data
  const date = post.custom_date || first_publication_date

  return (
    <Layout>
      <Box variant="layout.wrapper.medium">
        <Heading as="h1">{post.post_title.text}</Heading>
        {post.featured_image && <Img fluid={post.featured_image.fluid} />}
        <Box sx={{mb: 5, mt: 2}}>
          <Text sx={{pr: 2}}>Posted on {date}</Text>
          {tags.map((tag, i) => <Text key={`${tag}-i`} variant="pill">{tag}</Text>)}
        </Box>
        <Slicezone slices={post.body} />
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query PrismicPostQuery($id: String!) {
    prismicPosts(id: { eq: $id }) {
      data {
        featured_image {
          fluid(maxWidth:5000) {
            ...GatsbyPrismicImageFluid
          }
        }
        post_title {
          text
        }
        custom_date
        body {
          ... on PrismicPostsBodyText {
            slice_type
            primary {
              content {
                html
              }
            }
          }
          ... on PrismicPostsBodyMedia {
            slice_type
            primary {
              media {
                url
              }
            }
          }
          ... on PrismicPostsBodyImageGallery {
            slice_type
            items {
              gallery_image {
                fluid(maxWidth:5000) {
                  ...GatsbyPrismicImageFluid
                }
              }
              image_captions {
                text
              }
            }
          }
        }
      }
      first_publication_date(formatString: "MMMM DD, YYYY")
      tags
    }
  }
`
