/** @jsx jsx */
import { jsx, Box, Text } from "theme-ui"
import Img from "gatsby-image"

const ImageGallery = slice => {
  return (
    <Box sx={{
      display: "grid",
      gridTemplateColumns: "repeat(auto-fit, minmax(275px, 1fr))",
      gap: "1rem",
      my: 4
    }}>
      {slice.slice.items.map((item, index) => (
        <Box>
          <Img sx={{ width: "100%" }} fluid={item.gallery_image.fluid} key={index} />
          {item.hasOwnProperty("image_captions") && (
            <Text sx={{ fontSize: 0, fontStyle: "italic" }}>{item.image_captions.text}</Text>
          )}
        </Box>
      ))}
    </Box>
  )
}

const BasicContent = slice => {
  let content = slice.slice.primary
  content = content.hasOwnProperty("text")
    ? slice.slice.primary.text.html
    : slice.slice.primary.content.html
  return (
    <Box dangerouslySetInnerHTML={{ __html: content }} />
  )
}

const Media = slice => (
  <video
    controls
    src={slice.slice.primary.media.url}
    sx={{width: "100%", my: 4}}
  />
)

const Slicezone = ({ slices }) => {
  return slices.map((slice, index) => {
    const { slice_type } = slice
    if (slice_type === "image_gallery") {
      return <ImageGallery slice={slice} key={`slice-${index}`} />
    }
    else if (slice_type === "text") {
      return <BasicContent slice={slice} key={`slice-${index}`} />
    }
    else if (slice_type === "media") {
      return <Media slice={slice} key={`slice-${index}`} />
    }
    else {
      return null
    }
  })
}

export default Slicezone